import * as React from "react"

import { Layout } from "../components"

const NotFoundPage = () => (
  <Layout>
    <h1>Site Not Found</h1>
  </Layout>
)

export default NotFoundPage
